// @flow
import * as React from 'react';
import { Analytics, AnalyticsContext, trackEvent } from 'latitude-analytics';
import styled, { type ReactComponentStyled } from 'styled-components';
import classnames from 'classnames';
import { parseContent } from '../../utils/helpers';
import { BREAKPOINT, CALCULATOR_TRACKING } from '../../utils/constants';
import './_radio-boxes.scss';

const component = 'radio-box';

const StyledLabel: ReactComponentStyled<StyledProps> = styled.label`
  flex-basis: ${({ equalWidths, noOfItems }: StyledProps) =>
    equalWidths ? `${100 / Number(noOfItems)}%` : 'auto'};
  width: ${({ equalWidths, noOfItems }: StyledProps) =>
    equalWidths ? `${100 / Number(noOfItems)}%` : 'auto'};
  padding: ${({ equalWidths }: StyledProps) =>
    equalWidths ? `0.75rem 0px` : '0.75rem'};

  @media (max-width: ${BREAKPOINT.SM}) {
    padding: ${({ equalWidths }: StyledProps) =>
      equalWidths ? `1rem 0px` : '1rem'};
  }
`;

type StyledProps = {
  /**  The total number of radiobox labels inside the radiobox */
  noOfItems: string,
  /**  Whether or not the widths of each radiobox label should be equal or not */
  equalWidths?: boolean
};

type RadioBoxValueItem = {
  text: string,
  value: string
};

type Props = {
  /**  Name of input */
  name: string,
  /**  Label of input, displayed above */
  labelText?: string,
  /**  List of value data items */
  values: RadioBoxValueItem[],
  /**  Value of selected item **/
  selectedValue?: string | number,
  /**  If to show error message */
  showError?: boolean,
  /**  Error message that should be displayed when showError is true */
  showErrorMessage?: string,
  /**  CSS classes */
  className?: string,
  /**  onChange handler, use to get value changes */
  onChange: () => void,
  /**  Inverted boolean to control whether or not to display the inverted colors variant */
  inverted?: boolean,
  /**  Whether or not the widths of each radiobox label should be equal or not */
  equalWidths?: boolean,
  /**  Sets custom plural label (e.g. year/years) */
  pluralLabel?: string
};

const RadioBoxes = (props: Props) => {
  const {
    labelText,
    values,
    selectedValue,
    name,
    onChange,
    showError,
    showErrorMessage,
    className,
    inverted,
    equalWidths,
    pluralLabel
  } = props;

  const labelErrorClass = showError ? `${component}__label-error` : '';

  return (
    <AnalyticsContext.Consumer>
      {([analytics]: [Analytics]) => (
        <div className="input-wrap">
          {labelText && (
            /* eslint-disable-next-line */
            <label
              className={classnames(
                labelErrorClass,
                inverted ? `${component}__label-inverted` : ''
              )}
            >
              {parseContent(labelText)}
            </label>
          )}
          <div
            className={classnames(
              `radio-boxes`,
              inverted
                ? 'radio-boxes__inverted-color'
                : 'radio-boxes__default-color',
              className
            )}
          >
            {values.map((item: RadioBoxValueItem, index: number) => {
              let labelClass = `${component}__label`;
              const generatedId = `${component}-${name}-${index}`;
              const checked =
                selectedValue !== undefined &&
                selectedValue.toString() === item.value.toString();

              if (checked) {
                labelClass += ' active';
              }
              return (
                <StyledLabel
                  id={`${generatedId}-label`}
                  htmlFor={generatedId}
                  key={index}
                  className={labelClass}
                  equalWidths={equalWidths}
                  noOfItems={values.length}
                >
                  <input
                    id={generatedId}
                    type="radio"
                    className={`${component}__input`}
                    value={item.value}
                    checked={checked}
                    onChange={onChange}
                    onClick={(event: SyntheticEvent<HTMLInputElement>) => {
                      const pluralSuffix =
                        event.currentTarget.labels[0].textContent === '1'
                          ? ''
                          : 's';

                      if (trackEvent) {
                        trackEvent(analytics, {
                          category: CALCULATOR_TRACKING.CATEGORY,
                          action: CALCULATOR_TRACKING.ACTION,
                          label: pluralLabel
                            ? `${event.currentTarget.labels[0].textContent} ${pluralLabel}${pluralSuffix}`
                            : event.currentTarget.labels[0].textContent,
                          location: 'Calculate your repayments'
                        });
                      }
                    }}
                    name={name}
                  />
                  <span className={`${component}__text`}>
                    {parseContent(item.text)}
                  </span>
                </StyledLabel>
              );
            })}
          </div>
          {showError && (
            <div className={`${component}__error-container`}>
              <div className={`${component}__error-message`}>
                {showErrorMessage}
              </div>
            </div>
          )}
        </div>
      )}
    </AnalyticsContext.Consumer>
  );
};

export default RadioBoxes;
