import { LOAN_PAY_FREQUENCY } from './constants';
import { roundToPrecision } from './index';

/**
 * getPeriodicRate
 *
 * @param {number} annualRate - annual interest rate number, percentage
 * @param {string} frequency - repayment frequency, default to monthly
 * @returns {number} periodic interest rate number, not percentage
 */
export const getPeriodicRate = (
  annualRate = 0,
  frequency = LOAN_PAY_FREQUENCY.MONTHLY
) => {
  if (annualRate <= 0) {
    return 0;
  }
  // Note: interest rate is calculated based on 52-week year
  let frequencyDivider;
  switch (frequency) {
    case LOAN_PAY_FREQUENCY.WEEKLY:
      frequencyDivider = 52;
      break;
    case LOAN_PAY_FREQUENCY.FORTNIGHTLY:
      frequencyDivider = 26;
      break;
    case LOAN_PAY_FREQUENCY.MONTHLY:
    default:
      frequencyDivider = 12;
  }
  return annualRate / frequencyDivider / 100;
};

/**
 * getRepayment
 *
 * @param {number} principle - loan principle amount, once-off fees can be added into principle
 * @param {number} annualRate - annual loan interest rate, as percentage number
 * @param {number} termInYear - loan term in years
 * @param {string} frequency - repayment frequency, default to monthly
 * @param {number} monthlyFee - monthly loan service fees, default to 0
 * @param {boolean} roundResult - if result should be rounded to integer
 * @returns {number} loan repayment amount
 */
export const getRepayment = (
  principle = 0,
  annualRate = 0,
  termInYear = 0,
  frequency = LOAN_PAY_FREQUENCY.MONTHLY,
  monthlyFee = 0,
  roundResult = true,
  icbsAmortisationMultiplier = 1
) => {
  if (principle <= 0 || annualRate <= 0 || termInYear <= 0 || monthlyFee < 0) {
    return 0;
  }

  // work out monthly payment first
  const intLoanTerm = termInYear * 12;
  const totalCredit = principle;
  const intMonthlyApr = (annualRate / 12)/100;
  const f = Math.exp(-intLoanTerm * Math.log((1+intMonthlyApr)));
  const totalCreditRepayment = (totalCredit*intMonthlyApr)/(1-(f))*icbsAmortisationMultiplier;

  const monthlyPayment = totalCreditRepayment + monthlyFee;

  // return repayment based on frequency
  // frequency of payment is based on our 48-week year
  let repayment;
  switch (frequency) {
    case LOAN_PAY_FREQUENCY.WEEKLY:
      repayment = monthlyPayment / 4;
      break;
    case LOAN_PAY_FREQUENCY.FORTNIGHTLY:
      repayment = monthlyPayment / 2;
      break;
    case LOAN_PAY_FREQUENCY.MONTHLY:
    default:
      repayment = monthlyPayment;
  }
  // Note: business practice to round up for display result
  return roundResult ? Math.ceil(repayment) : repayment;
};

/**
 * getAmortisationSchedule
 *
 * @param {number} principle - loan principle amount, once-off fees can be added into principle
 * @param {number} annualRate - annual loan interest rate, as percentage number
 * @param {number} payment - amount of periodic payment, excluding any monthly fees
 * @param {string} frequency - repayment frequency, default to monthly
 * @returns {[]|Array} payment schedule array
 */
export const getAmortisationSchedule = (
  principle = 0,
  annualRate = 0,
  payment = 0,
  frequency = LOAN_PAY_FREQUENCY.MONTHLY
) => {
  if (principle <= 0 || annualRate <= 0 || payment <= 0) {
    return [];
  }

  let remainingPrinciple = principle;
  let schedule = [];

  let rate;
  switch (frequency) {
    case LOAN_PAY_FREQUENCY.WEEKLY:
      rate = getPeriodicRate(annualRate, LOAN_PAY_FREQUENCY.WEEKLY);
      break;
    case LOAN_PAY_FREQUENCY.FORTNIGHTLY:
      rate = getPeriodicRate(annualRate, LOAN_PAY_FREQUENCY.FORTNIGHTLY);
      break;
    case LOAN_PAY_FREQUENCY.MONTHLY:
    default:
      rate = getPeriodicRate(annualRate);
  }
  while (remainingPrinciple > 0) {
    const interest = roundToPrecision(remainingPrinciple * rate, 2);
    const principleInPayment = payment - interest;

    // 'principleInPayment, interest' should always be positive,
    // otherwise causes infinite loops
    if (principleInPayment <= 0 || interest <= 0) {
      break;
    }

    // update total principle and add payment schedule
    remainingPrinciple -= principleInPayment;
    if (remainingPrinciple < 0) {
      remainingPrinciple = 0;
    }
    schedule.push({
      remaining: roundToPrecision(remainingPrinciple, 2),
      interest: interest
    });
  }

  return schedule;
};

/**
 * getTotalInterest
 *
 * @param {array} paymentSchedule - list of payment schedule details
 * @param {boolean} roundResult - if result should be rounded to integer
 * @returns {number}
 */
export const getTotalInterest = (paymentSchedule = [], roundResult = true) => {
  if (!Array.isArray(paymentSchedule) || paymentSchedule.length === 0) {
    return 0;
  }
  const totalInterest = paymentSchedule.reduce((total, item) => {
    return (total += item['interest']);
  }, 0);
  // Note: business practice to round up for display result
  return roundResult ? Math.ceil(totalInterest) : totalInterest;
};

/**
 * getTotalAccountServiceFee
 *
 * @param {number} monthlyFee - monthly loan service fees, default to 0
 * @param {number} paymentScheduleLength - loan repayment schedule length
 * @param {string} frequency - repayment frequency, default to monthly
 * @returns {number} total account service fee for the term based on frequency
 */
export const getTotalAccountServiceFee = (
  monthlyFee = 0,
  paymentScheduleLength = 0,
  frequency = LOAN_PAY_FREQUENCY.MONTHLY
) => {
  if (monthlyFee <= 0 || paymentScheduleLength <= 0) {
    return 0;
  }

  // Note: monthly account fee is calculated based on 48-week year
  let feePerInstallment;
  switch (frequency) {
    case LOAN_PAY_FREQUENCY.WEEKLY:
      feePerInstallment = monthlyFee / 4;
      break;
    case LOAN_PAY_FREQUENCY.FORTNIGHTLY:
      feePerInstallment = monthlyFee / 2;
      break;
    case LOAN_PAY_FREQUENCY.MONTHLY:
    default:
      feePerInstallment = monthlyFee;
  }

  return feePerInstallment * paymentScheduleLength;
};

/**
 * getAccountServiceFeePerPayment
 *
 * @param {number} monthlyFee - monthly loan service fees, default to 0
 * @param {string} frequency - repayment frequency, default to monthly
 * @returns {number} account service fee for per payment
 */
export const getAccountServiceFeePerPayment = (
  monthlyFee = 0,
  frequency = LOAN_PAY_FREQUENCY.MONTHLY
) => {
  if (monthlyFee <= 0) {
    return 0;
  }

  // Note: monthly account fee is calculated based on 48-week year
  let feePerInstallment;
  switch (frequency) {
    case LOAN_PAY_FREQUENCY.WEEKLY:
      feePerInstallment = monthlyFee / 4;
      break;
    case LOAN_PAY_FREQUENCY.FORTNIGHTLY:
      feePerInstallment = monthlyFee / 2;
      break;
    case LOAN_PAY_FREQUENCY.MONTHLY:
    default:
      feePerInstallment = monthlyFee;
  }

  return feePerInstallment;
};

/**
 * getRepaymentFigures
 *
 * Notes:
 * - this is a convenient method that returns all required figures for calculator UI
 *
 * @param {number} principle - loan principle amount, once-off fees can be added into principle
 * @param {number} annualRate - annual loan interest rate, as percentage number
 * @param {number} termInYear - loan term in years
 * @param {string} frequency - repayment frequency, default to monthly
 * @param {number} monthlyFee - monthly loan service fees, default to 0
 * @param {number} extraRepayment - extra amount contributed to repayment per installment
 * @param {boolean} roundResult - if result should be rounded to integer
 * @returns {Object} result - Repayment figures object
 */
export const getRepaymentFigures = (
  principle = 0,
  annualRate = 0,
  termInYear = 0,
  frequency = LOAN_PAY_FREQUENCY.MONTHLY,
  monthlyFee = 0,
  extraRepayment = 0,
  roundResult = true,
  useICBS = false,
) => {
  const result = {
    repayment: 0,
    totalInterest: 0,
    totalAmountPaid: 0,
    interestSavedByExtra: 0,
    timeSavedByExtra: 0
  };
  if (
    principle <= 0 ||
    annualRate <= 0 ||
    termInYear <= 0 ||
    monthlyFee < 0 ||
    extraRepayment < 0
  ) {
    return result;
  }

  // Determine if extra repayment is contributed
  const isExtraContributed = extraRepayment > 0;

  // 1: get repayment amount
  // Note:
  // - round is off due to payment is used in further calculation
  // - 'payment' includes any service fees
  // icbsAmortisationMultiplier (1.0018) is only for personal loan calculator
  const icbsAmortisationMultiplier = useICBS ? 1.0018 : 1;
  const payment = getRepayment(
    principle,
    annualRate,
    termInYear,
    frequency,
    monthlyFee,
    false,
    icbsAmortisationMultiplier
  );
  // 'totalPayment' includes any extra contribution
  const totalPayment = payment + extraRepayment;
  // get account service fee per selected payment frequency
  // this is not included in the calculation for repayment interest
  const accountServiceFeePerPayment = getAccountServiceFeePerPayment(
    monthlyFee,
    frequency
  );
  // 'actualRepayment' excludes any service fees
  // the actual amount that will reduce the remaining principle
  const actualRepayment = totalPayment - accountServiceFeePerPayment;

  // 2: generate entire payment schedule based on repayment
  const paymentSchedule = getAmortisationSchedule(
    principle,
    annualRate,
    actualRepayment,
    frequency
  );
  // 3: get total interest amount
  // Note: round is off due to totalInterest is used in further calculation
  let totalInterest = getTotalInterest(paymentSchedule, false);

  // 4: get total account service amount
  const totalAccountServiceFee = getTotalAccountServiceFee(
    monthlyFee,
    paymentSchedule.length,
    frequency
  );

  // 5: work out key values if extra repayment is contributed
  if (isExtraContributed) {
    // generate payment schedule based on payment without extra contribution
    const paymentScheduleWithoutExtra = getAmortisationSchedule(
      principle,
      annualRate,
      payment - accountServiceFeePerPayment,
      frequency
    );

    // calculate total interest amount without extra contribution
    const totalInterestWithoutExtra = getTotalInterest(
      paymentScheduleWithoutExtra,
      false
    );

    // update result with key values fields
    const interestSaved = totalInterestWithoutExtra - totalInterest;
    const timeSaved =
      paymentScheduleWithoutExtra.length - paymentSchedule.length;
    result.interestSavedByExtra = roundResult
      ? Math.ceil(interestSaved)
      : interestSaved;
    result.timeSavedByExtra = timeSaved;
  }

  const totalCredit = principle;
  const intLoanTerm = termInYear * 12;
  const totalCreditRepaymentRounded = payment.toFixed(2) - monthlyFee;
  const totalInterestAmountPayable = totalCreditRepaymentRounded * intLoanTerm - totalCredit;

  // update result
  result.repayment = roundResult ? Math.ceil(totalPayment) : totalPayment;

  // 6: calculate total amount paid for the loan
  let totalAmountPaid = principle + totalInterestAmountPayable + totalAccountServiceFee;

  // If there is a contribution or repayment frequency is either WEEKLY or FORTNIGHTLY,
  // totalAmountPaid is calculated using getAmortisationSchedule() data
  if (isExtraContributed || frequency === LOAN_PAY_FREQUENCY.WEEKLY || frequency === LOAN_PAY_FREQUENCY.FORTNIGHTLY) {
    totalAmountPaid = principle + totalInterest + totalAccountServiceFee;
  }

  // If the Frequency payment is monthly and there is no extra repayment, use the totalInterestAmountPayable
  // which is the same formula with Salesforce. This aligns with what is in the customer contract
  if (!isExtraContributed && frequency === LOAN_PAY_FREQUENCY.MONTHLY) {
    totalInterest = totalInterestAmountPayable;
  }

  result.totalInterest = roundResult ? Math.ceil(totalInterest) : totalInterest;
  result.totalAmountPaid = roundResult
    ? Math.ceil(totalAmountPaid)
    : totalAmountPaid;

  return result;
};
